var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('lottie', _vm._g(_vm._b({
    attrs: {
      "options": _vm.lottieOptions
    }
  }, 'lottie', _vm.$attrs, false), _vm.$listeners));
};
var staticRenderFns = [];
export { render, staticRenderFns };